<template>
    <div class="container">
        <div class="row justify-content-center mb-3">
            <div class="col-xl-6 col-sm-6 py-2"  v-if="dashboard.users">
                <div class="card bg-sc-color-b text-white h-100">
                    <div class="card-body bg-sc-color-b">
                        <div class="rotate">
                            <i class="fa fa-user fa-4x"></i>
                        </div>
                        <h6 class="text-uppercase">API Users</h6>
                        <h1 class="display-4" v-if="dashboard.users">{{ dashboard.users }}</h1>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-sm-6 py-2" v-if="dashboard.instances">
                <div class="card text-white bg-sc-color-d h-100">
                    <div class="card-body bg-sc-color-d">
                        <div class="rotate">
                            <i class="fa fa-sitemap fa-4x"></i>
                        </div>
                        <h6 class="text-uppercase">My Instances</h6>
                        <h1 class="display-4" v-if="dashboard.instances">{{ dashboard.instances }}</h1>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-sm-6 py-2" v-if="dashboard.logs">
                <div class="card text-white bg-sc-color-c h-100">
                    <div class="card-body bg-sc-color-c">
                        <div class="rotate">
                            <i class="fa fa-list fa-4x"></i>
                        </div>
                        <h6 class="text-uppercase">API Log Entries</h6>
                        <h1 class="display-4" v-if="dashboard.logs">{{ dashboard.logs }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
      data() {
        return {
          dashboard: []
        }
      },
      created() {
        let uri = '/dashboard';
        this.axios.get(uri).then(response => {
          this.dashboard = response.data;
        });
      },
    }
</script>

<style>
    .card {
        overflow:hidden;
    }

    .card-body .rotate {
        z-index: 8;
        float: right;
        height: 100%;
    }

    .card-body .rotate i {
        color: rgba(20, 20, 20, 0.15);
        position: absolute;
        left: 0;
        left: auto;
        right: -10px;
        bottom: 0;
        display: block;
        -webkit-transform: rotate(-44deg);
        -moz-transform: rotate(-44deg);
        -o-transform: rotate(-44deg);
        -ms-transform: rotate(-44deg);
        transform: rotate(-44deg);
    }
</style>