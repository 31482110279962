<template>
  <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="alert alert-info" role="alert">
          Contact StarChapter support for additional instances.
        </div>
      </div>
      <div class="col-md-12">
          <div class="card card-default">
              <div class="card-header">My Instances</div>

              <div class="card-body">
                <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">URL</th>
                        <th scope="col">Date Added</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="instance in instances">
                            <td>{{ instance.domain }}</td>
                            <td>{{ instance.url }}</td>
                            <td>{{ instance.pivot.created_at }}</td>
                        </tr>
                    </tbody>
                </table>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
  export default {
      data() {
        return {
          instances: []
        }
      },
      created() {
        let uri = '/my-instances';
        this.axios.get(uri).then(response => {
          this.instances = response.data;
        });
      },
    methods: {

    }
  }
</script>