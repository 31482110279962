<template>
  <div class="row justify-content-center">
      <div class="col-md-12">
          <div class="card card-default">
              <div class="card-header">API Users</div>

              <div class="card-body">
                <div class="row mb-3">
                  <div class="col-md-12">
                    <router-link :to="{ name: 'users/create' }" class="btn btn-success float-right"><font-awesome-icon icon="plus"></font-awesome-icon> New User</router-link>
                  </div>
                </div>

                <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th scope="col">Organization</th>
                        <th scope="col">Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Username</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in users" :key="user.id">
                            <td>{{ user.organization }}</td>
                            <td>{{ user.last_first_name }}</td>
                            <td>{{ ucFirst(user.status) }}</td>
                            <td>{{ user.username }}</td>
                            <td>
                              <router-link :to="{name: 'users/edit', params: { id: user.id }}" class="btn btn-primary btn-sm"><font-awesome-icon icon="user-edit"></font-awesome-icon> Edit</router-link>

                              <!--<button class="btn btn-danger btn-sm" @click.prevent="deleteUser(user.id)"><font-awesome-icon icon="trash"></font-awesome-icon> Delete</button>-->
                            </td>
                        </tr>
                    </tbody>
                </table>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
  export default {
      data() {
        return {
          users: []
        }
      },
      created() {
        let uri = '/user';
        this.axios.get(uri).then(response => {
          this.users = response.data.data;
        });
      },
    methods: {
      deleteUser(id)
      {
        let uri = '/user/' + id;
        this.axios.delete(uri).then(response => {
          this.users.splice(this.users.indexOf(id), 1);
        });
      },
      ucFirst(string)
      {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    }
  }
</script>