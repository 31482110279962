<template>
  <div class="row justify-content-center">
      <div class="col-md-12">
          <div class="card card-default">
              <div class="card-header">API Logs</div>

              <div class="card-body">
                <form class="row" @submit.prevent="search">
                  <div class="form-group col-md-4">
                    <label for="date_from">Date From:</label>
                    <datepicker :bootstrap-styling="true" :clear-button="true" format="yyyy-MM-dd" v-model="log.date_from"></datepicker>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="date_to">Date To:</label>
                    <datepicker :bootstrap-styling="true" :clear-button="true" format="yyyy-MM-dd" v-model="log.date_to"></datepicker>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="type">Type of Entry:</label>
                    <input type="text" class="form-control" v-model="log.type">
                  </div>

                  <div class="form-group col-md-4">
                    <label for="username">Username:</label>
                    <input type="text" class="form-control" v-model="log.username">
                  </div>

                  <div class="form-group col-md-4">
                    <label for="ip">IP Address:</label>
                    <input type="text" class="form-control" v-model="log.ip">
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">&nbsp;</label>
                    <button class="btn btn-success form-control"><font-awesome-icon icon="search"></font-awesome-icon> Search</button>
                  </div>

                </form>
                <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th scope="col">Date/Time of Entry</th>
                        <th scope="col">Type of Entry</th>
                        <th scope="col">Username for Entry</th>
                        <th scope="col">Summary Result</th>
                        <th scope="col">IP Address</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="log in logs">
                            <td>{{ log.created_at }}</td>
                            <td>{{ log.type | capitalize }}</td>
                            <td v-if="log.user && log.user.username">{{ log.user.username }}</td>
                            <td v-else></td>
                            <td>{{ log.summary }}</td>
                            <td>{{ log.ip }}</td>
                            <td>
                              <router-link data-toggle="modal" data-target="#exampleModal" class="btn btn-sm btn-primary" :to="{name: 'logs/show', params: {id: log.id}}">View</router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="row">
                  <div class="form-group col-md-2">
                    <select class="form-control form-control-sm" v-model="log.per_page" @change="search()">
                      <option v-for="option in paginate.options" v-bind:value="option.value">
                      {{ option.text }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-10">
                    <paginate v-if="paginate.last_page > 1"
                      :pageCount="paginate.last_page"
                      :clickHandler="paginateCallback"
                      :prevText="'Previous'"
                      :nextText="'Next'"
                      :containerClass="'pagination justify-content-end'"
                      :page-class="'page-item'"
                      :page-link-class="'page-link'"
                      :prev-link-class="'page-link'"
                      :next-link-class="'page-link'"
                      :hide-prev-next="true">
                    </paginate>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
  export default {
      data() {
        return {
          log: {
            page: 1,
            per_page: 10,
          },
          logs: [],
          paginate: {
            last_page: 1,
            options: [
              { text: '10 Per Page', value: 10 },
              { text: '25 Per Page', value: 25 },
              { text: '50 Per Page', value: 50 },
              { text: '100 Per Page', value: 100 }
            ]
          },
        }
      },
      created() {
        this.search()
      },
      methods: {
        search(){
            this.errors = [];
  
            let uri = '/data-log';
            this.axios.get(uri, {params: this.log}).then((response) => {
              this.logs = response.data.data;
              this.paginate.last_page = response.data.last_page
              if(this.paginate.last_page < this.log.page) {
                this.log.page = this.paginate.last_page
                this.search()
              }
            })
        },
        paginateCallback: function(pageNum) {
          this.log.page = pageNum
          this.search()
        }
      }
    }
</script>