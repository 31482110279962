<template>
  <div v-if="ifModal" v-show="showModal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">

        <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ log.type | capitalize }} Log Entry</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" @click.prevent="closeModal">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                <div class="form-group row">
                  <div class="col-sm-2">
                    <h6>Date/Time:</h6>
                  </div>
                  <div class="col-sm-10">
                    <div class="card card-body bg-light">{{ log.created_at }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="type" class="col-sm-2 col-form-label">Type:</label>
                  <div class="col-sm-10">
                    <div class="card card-body bg-light">{{ log.type }}</div>
                  </div>
                </div>

                <div class="form-group row" v-if="log.user">
                  <label for="user.username" class="col-sm-2 col-form-label">Username:</label>
                  <div class="col-sm-10">
                    <div class="card card-body bg-light">{{ log.user.username }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="ip" class="col-sm-2 col-form-label">IP:</label>
                  <div class="col-sm-10">
                    <div class="card card-body bg-light">{{ log.ip }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="url" class="col-sm-2 col-form-label">Url:</label>
                  <div class="col-sm-10">
                    <div class="card card-body bg-light">{{ log.url }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="path" class="col-sm-2 col-form-label">Path:</label>
                  <div class="col-sm-10">
                    <div class="card card-body bg-light">{{ log.path }}</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="request" class="col-sm-2 col-form-label">Request:</label>
                  <div class="col-sm-10">
                    <div class="card card-body bg-light"><code>{{ log.request }}</code></div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="response" class="col-sm-2 col-form-label">Response:</label>
                  <div class="col-sm-10">
                    <div class="card card-body bg-light"><code>{{ log.response }}</code></div>
                  </div>
                </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click.prevent="closeModal">Close</button>
                </div>
            </div>
        </div>

        </div>
      </div>
    </transition>
  </div>
</template>

<script>
    export default {
      data() {
        return {
          ifModal: true,
          showModal: false,
          from: null,
          log: {},
        }
      },
      beforeRouteEnter(to, from, next) {
          next((vm) => {
              vm.from = from;
          });
      },
      created() {
        this.show()
      },
      methods: {
        show(){
            this.errors = [];

            let uri = '/data-log/' + this.$route.params.id;
            this.axios.get(uri).then((response) => {
              this.log = response.data;
              this.showModal = true
            })
            .catch((error) => {
              var errors = error.response.data.errors

              for (var key in errors) {
                if (errors.hasOwnProperty(key)) {
                  for(var i = 0; i < errors[key].length; i++) {
                    this.$toasted.error(errors[key][i])
                  }
                }
              }

              this.closeModal()
            })
        },
        closeModal() {
          if(this.from.name === null) {
            this.$router.push({ name: 'logs/'})
          } else {
            this.$router.go(-1)
          }

        }
      }
    }
</script>

<style>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
</style>