/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import Vue from 'vue';

window.Vue = require('vue');

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import VueAxios from 'vue-axios';
import axios from 'axios';
Vue.use(VueAxios, axios);

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faEdit, faUndo, faEnvelope, faUserEdit, faSave, faTrash, faKey, faSearch, faUser, faUserMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faPlus, faEdit, faUndo, faEnvelope, faUserEdit, faSave, faTrash, faKey, faSearch, faUser, faUserMinus)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

import Toasted from 'vue-toasted';

Vue.use(Toasted, {
   theme: "bubble",
   position: "bottom-right",
   duration : 5000
})

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

import Multiselect from 'vue-multiselect'

import Datepicker from 'vuejs-datepicker';

import Paginate from 'vuejs-paginate'

import HomeIndexComponent from './components/home/IndexComponent.vue';

import UsersIndexComponent from './components/users/IndexComponent.vue';
import UsersCreateComponent from './components/users/CreateComponent.vue';
import UsersEditComponent from './components/users/EditComponent.vue';

import LogsIndexComponent from './components/logs/IndexComponent.vue';
import LogsShowComponent from './components/logs/ShowComponent.vue';

import MyInstancesIndexComponent from './components/myinstances/IndexComponent.vue';

import UserEditPasswordComponent from './components/user/EditPasswordComponent.vue';

import DocumentationIndexComponent from './components/documentation/IndexComponent.vue';
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('app', require('./App.vue').default);
Vue.component('multiselect', Multiselect)
Vue.component('datepicker', Datepicker)
Vue.component('paginate', Paginate)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

function beforeEnterGuard(to, from, next) {
  if(routeGuards[guard].includes(to.name)) {
    next()
  } else {
    router.push({ name: '/'})
    next(false)
  }
}

const routeGuards = {
  web: ['change-password/', 'my-instances/'],
  admin_users_global: ['users/', 'users/create', 'users/edit'],
  guest: [],
}

const routes = [
  {
      name: '/',
      path: '/home',
      component: HomeIndexComponent,
  },
  {
      name: 'change-password/',
      path: '/home/change-password',
      component: UserEditPasswordComponent,
      beforeEnter: beforeEnterGuard
  },
  {
      name: 'users/',
      path: '/home/users',
      component: UsersIndexComponent,
      beforeEnter: beforeEnterGuard
  },
  {
      name: 'users/create',
      path: '/home/users/create',
      component: UsersCreateComponent,
      beforeEnter: beforeEnterGuard
  },
  {
      name: 'users/edit',
      path: '/home/users/edit/:id',
      component: UsersEditComponent,
      beforeEnter: beforeEnterGuard
  },
  {
      name: 'my-instances/',
      path: '/home/my-instances',
      component: MyInstancesIndexComponent,
      beforeEnter: beforeEnterGuard
  },
  {
      name: 'logs/',
      path: '/home/logs',
      component: LogsIndexComponent
  },
  {
      name: 'logs/show',
      path: '/home/logs/:id',
      components: {
        default: LogsIndexComponent,
        modal: LogsShowComponent
      }
  },
  {
      name: 'documentation/',
      path: '/home/documentation',
      component: DocumentationIndexComponent
  },
];

const router = new VueRouter(
	{ mode: 'history', routes: routes}
);

const app = new Vue(
	Vue.util.extend({ router })
).$mount('#app');

/*
const app = new Vue({
    el: '#app',
});
*/
