<template>
    <div class="container">
        <div>
            <router-view name="modal"></router-view>
            <transition name="fade">
                <router-view></router-view>
            </transition>
        </div>
    </div>
</template>

<style>
    .fade-enter-active {
      transition: opacity .5s
    }
    ..fade-leave-active {
      transition: opacity .1s
    }
    .fade-enter, .fade-leave-active {
      opacity: 0
    }
</style>

<script>
    export default {
    }
</script>