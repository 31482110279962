<template>
  <div class="row justify-content-center">
      <div class="col-md-12 mb-4" v-if="openapiauth.allowTry && openapiauth.allowTry == 'false'">
          <div class="card card-default">
              <div class="card-header">API Server</div>
              <div class="card-body">
                <code>{{ openapiauth.serverUrl }}</code>
              </div>
          </div>
      </div>

      <div class="col-md-12">
          <div class="card card-default">
              <div class="card-header">Documentation</div>

              <div class="card-body">
                <rapi-doc v-if="openapiauth.specUrl"
                  v-bind:spec-url="openapiauth.specUrl"
                  v-bind:server-url="openapiauth.serverUrl"
                  show-header="false"
                  regular-font="'Varela Round', 'Arial Rounded MT Bold', 'Helvetica Rounded' "
                  v-bind:allow-try="openapiauth.allowTry"
                  allow-authentication="false"
                  api-key-name="Authorization"
                  v-bind:api-key-value="openapiauth.apiKeyValue"
                  api-key-location="header"
                ></rapi-doc>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
  export default {
      data() {
        return {
          openapiauth: []
        }
      },
      created() {
        let uri = '/openapiauth';
        this.axios.get(uri).then(response => {
          this.openapiauth = response.data;
        });
      },
    methods: {

    }
  }
</script>