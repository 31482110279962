<template>
  <div class="row justify-content-center">
      <div class="col-md-8">
          <div class="card card-default">
              <div class="card-header">Change Password</div>

              <div class="card-body">
                    <div v-for="error in errors" class="alert alert-danger" role="alert">
                      {{ error }}
                    </div>

                    <form @submit.prevent="update">
                        <div class="form-group row">
                            <label for="new-password" class="col-md-4 col-form-label text-md-right">Current Password</label>

                            <div class="col-md-6">
                                <input type="password" class="form-control" v-model="user.current_password" autofocus required>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="new-password" class="col-md-4 col-form-label text-md-right">New Password</label>

                            <div class="col-md-6">
                                <input type="password" class="form-control" v-model="user.new_password" required>
                                <small id="emailHelp" class="form-text text-muted">10 character minimum, 1 letter, 1 number, 1 symbol required</small>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="new-password-confirmation" class="col-md-4 col-form-label text-md-right">New Password Confirmation</label>

                            <div class="col-md-6">
                                <input type="password" class="form-control" v-model="user.new_password_confirmation" required>
                            </div>
                        </div>

                        <div class="form-group row mb-0">
                            <div class="col-md-12">
                                <div class="float-right">
                                  <button @click.prevent="$router.go(-1)" class="btn btn-secondary"><font-awesome-icon icon="undo"></font-awesome-icon> Cancel</button>
                                
                                  <button class="btn btn-success"><font-awesome-icon icon="key"></font-awesome-icon> Change Password</button>
                              </div>
                            </div>
                        </div>

                    </form>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
    export default {

      data() {
        return {
            user: {},
            errors: []
        }
      },
      methods: {
          update() {
            this.errors = [];

            this.axios.post('/user/password/change', this.user)
            .then((response) => {
                if(response.data == 'success') {
                  this.$toasted.success('Password Updated!')
                } else {
                  this.$toasted.error('Oops! There was a problem.')
                }
            })
            .catch((error) => {
              this.$toasted.error(error.response.data.message)

              var errors = error.response.data.errors

              for (var key in errors) {
                if (errors.hasOwnProperty(key)) {
                  for(var i = 0; i < errors[key].length; i++) {
                    this.errors.push(errors[key][i])
                  }
                }
              }

            })
          }
      }
    }
</script>