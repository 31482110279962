<template>
  <div class="row justify-content-center">
      <div class="col-md-8">
          <div class="card card-default">
              <div class="card-header">Create User</div>

              <div class="card-body">
                    <div v-for="error in errors" class="alert alert-danger" role="alert">
                      {{ error }}
                    </div>

                    <form @submit.prevent="store">
                        <div class="form-group row">
                            <label for="organization" class="col-md-4 col-form-label text-md-right">Organization</label>

                            <div class="col-md-6">
                                <input type="text" class="form-control" v-model="user.organization" autofocus required>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="first_name" class="col-md-4 col-form-label text-md-right">First Name</label>

                            <div class="col-md-6">
                                <input type="text" class="form-control" v-model="user.first_name" required>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="last_name" class="col-md-4 col-form-label text-md-right">Last Name</label>

                            <div class="col-md-6">
                                <input type="text" class="form-control" v-model="user.last_name" required>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="username" class="col-md-4 col-form-label text-md-right">Username</label>

                            <div class="col-md-6">
                                <input type="text" class="form-control" v-model="user.username" required>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="email" class="col-md-4 col-form-label text-md-right">E-Mail Address</label>

                            <div class="col-md-6">
                                <input type="email" class="form-control" v-model="user.email" required>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="status" class="col-md-4 col-form-label text-md-right">Status</label>

                            <div class="col-md-6">
                                <select class="form-control" v-model="user.status" required>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                    <option value="suspended">Suspended</option>
                                    <option value="terminated">Terminated</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <!-- https://vue-multiselect.js.org/ -->
                            <label for="instances" class="col-md-4 col-form-label text-md-right">Allowed Instances</label>

                            <div class="col-md-6">
                                <multiselect v-model="user.instances" :options="options" :multiple="true" :taggable="true" label="domain" track-by="record_number" :close-on-select="false" :clear-on-select="false" required></multiselect>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="environments" class="col-md-4 col-form-label text-md-right">Target Environments</label>

                            <div class="col-md-6">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="user.development">
                                <label class="form-check-label" for="defaultCheck1">
                                  Development
                                </label>
                              </div>

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="user.staging">
                                <label class="form-check-label" for="defaultCheck1">
                                  Staging
                                </label>
                              </div>

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="user.production">
                                <label class="form-check-label" for="defaultCheck1">
                                  Production
                                </label>
                              </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="environments" class="col-md-4 col-form-label text-md-right">Rest Calls from Documentation</label>

                            <div class="col-md-6">
                                <toggle-button v-model="user.allow_try" :value="user.allow_try" color="#82C7EB" :sync="true" :labels="true" :width="80" :height="32" :font-size="18"/>
                            </div>
                        </div>

                        <div class="form-group row mb-0">
                            <div class="col-md-12">
                                <div class="float-right">
                                  <button @click.prevent="$router.go(-1)" class="btn btn-secondary"><font-awesome-icon icon="undo"></font-awesome-icon> Cancel</button>
                                
                                  <button class="btn btn-success"><font-awesome-icon icon="save"></font-awesome-icon> Create User</button>
                              </div>
                            </div>
                        </div>

                    </form>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
    export default {
        data(){
          return {
            user:{},
            value: null,
            options: [],
            errors: [],
          }
        },
        created() {
            let uri = '/instances';
            this.axios.get(uri).then(response => {
                this.options = response.data;
            });
        },
        methods: {
          store(){
              this.errors = [];

              let uri = '/user';
              this.axios.post(uri, this.user).then((response) => {
                if(response.data == 'success') {
                  this.$toasted.success('User Created!')
                  this.$router.push({name: 'users/'});
                } else {
                  this.$toasted.error('Oops! There was a problem.')
                }
              })
              .catch((error) => {
                this.$toasted.error(error.response.data.message)

                var errors = error.response.data.errors

                for (var key in errors) {
                  if (errors.hasOwnProperty(key)) {
                    for(var i = 0; i < errors[key].length; i++) {
                      this.errors.push(errors[key][i])
                    }
                  }
                }

              })
          }
        }
    }
</script>